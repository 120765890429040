#display {
  background: #22223B;
}

.app .ant-layout-content {
  height: calc(100vh - 110px);
  border-top: none;
}

.app .ant-layout-footer {
  padding: 7px 50px;
  height: 45px;
  background: #151525;
}

.app .ant-layout-footer .console {
  opacity: 0.3;
  background: #151525;
}

.app .ant-layout-footer .console:focus-within {
  opacity: 1;
}

.anticon.anticon-close-circle {
  color: white;
}

.app .ant-layout-sider {
  position: fixed;
  background: #00000055;
  z-index: 2;
  height: calc(100vh - 110px);
}

.app .ant-layout-sider .add {
  min-height: 50vh;
}

.app .ant-layout-sider .params {
  border-top: 1px solid #FFF3;
  padding: 4px;
}

.params .ant-form-item {
  margin-bottom: 4px;
}

.params .ant-space-horizontal {
  gap: 4px !important;
}

.params .ant-form-item-control-input-content .ant-btn-dashed {
  margin-right: 6px;
}

.params .ant-form {
  margin-right: 2px;
}

.ant-layout-header {
  background: #151525 !important;
}

.app .ant-layout-header h1 {
  color: white;
}

.app #display {
  width: 100%;
  height: 100%;
}

.params .title-editor {
  margin-right: 20px;
  margin-bottom: 6px;
  background: #00000099;
}

.requests-pending-count {
  color: white;
  float: right;
  font-size: 18px;
}

.app .add {
  padding: 4px;
  background: #00000000;
}

.app .add .ant-btn {
  width: calc(50% - 2px);
  margin-left: 2px;
  background: #000000CC;
  color: white;
}

.edge-name-btns {
  margin-bottom: 4px;
  background: black;
}

@media screen and (max-width: 600px) {
  #title_message {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.app-title {
  display: inline-block;
}

.is-admin {
  float: right;
  font-size: 16px;
  color: gray;
}